import React, { createContext } from 'react'
import jwtDecode from 'jwt-decode'

const authContext = createContext({
  authenticated: () => {},
  authenticationToken: () => {},
  logout: () => {}
})

export default authContext

export const isAuthTokenExpired = (authToken, token) => {
  if (authToken !== null) {
    const decoded = jwtDecode(authToken)
    if(token === null || token === undefined){
      token = decoded.ApiKey;
    }
    const expires = ((Date.now() / 1000) + (5 * 60));
    if (decoded.exp > expires && decoded.ApiKey === token) {
      return false
    }
  }
  return true;
}

export const authenticationToken = (token) => {
  const authToken = localStorage.getItem('authToken')
  return isAuthTokenExpired(authToken, token) ? null : authToken;
}
export const authenticated = (token) => {
  const authToken = localStorage.getItem('authToken')
  return !isAuthTokenExpired(authToken, token)
}

export const logout = () => {
  localStorage.removeItem('authToken')
}