import React from "react";

import { Modal, ModalBody, ModalHeader } from "reactstrap";

export const PolicyModal = (props) => {
  const toggle = () => props.setToggle(!props.toggle);

  return (
    <div>
      <Modal isOpen={props.toggle} toggle={toggle} size={"lg"}>
        <ModalHeader toggle={toggle}>
          PERSONAL DATA POLICY (For vendors)
        </ModalHeader>
        <ModalBody>
          <p>
            Hafnia Pools, filial af Hafnia Pools Pte. Ltd., Singapore (CVR no.
            40 51 35 23) (“Hafnia”), is subject to the Danish Data Protection
            Act and the General Data Protection Regulation, for which reason you
            are entitled to be informed of the personal data that Hafnia
            processes about you and your rights under the regulation.
          </p>

          <ol>
            <li>
              <div>
                <b>DATA CONTROLLER </b>
              </div>
              <div>
                Hafnia Pools, a branch of Hafnia Pools Pte. Ltd., Singapore,
                Hans Bekkevolds Allé 7, 2900 Hellerup, Denmark, phone +45 3369
                9000, email: info@hafniabw.com
              </div>
            </li>
            <li>
              <div>
                <b>PERSONAL DATA, DATA PURPOSE AND LAWFUL PROCESSING</b>
              </div>
              <div>
                <p>
                  In order to manage our cooperation with you as a vendor, we
                  need to process and store the following personal data:
                </p>
                <ul>
                  <li>
                    Your name (contact for the vendor), email address (work) and
                    phone number (work).
                  </li>
                </ul>
                <br></br>
                <p>
                  All the data referred to above will be processed in full
                  confidentiality in accordance with the General Data Protection
                  Regulation.
                </p>
                <p>
                  Hafnia’s authority to process the personal data derives from
                  Hafnia’s legitimate interest in our cooperation with you as a
                  vendor, see article 6 (1) (f) of the General Data Protection
                  Regulation.
                </p>
              </div>
            </li>
            <li>
              <div>
                <b>RECIPIENTS AND PROCESSORS OF YOUR PERSONAL DATA</b>
              </div>
              <div>
                <p>
                  Your personal data will be processed by Hafnia unless other
                  processing is relevant to the nature of the given task. Your
                  data may be transferred to our cooperative partners or to
                  authorities if such a transfer is necessary in connection with
                  your cooperation with us.
                </p>
                <p>
                  Your data may be transferred to our data processors, including
                  but not limited to, Microsoft Azure/Western Europe region and
                  SendGrid.
                </p>
                <p>
                  We normally receive the above-mentioned data directly from
                  you.{" "}
                </p>
              </div>
            </li>
            <li>
              <div>
                <b>TRANSFER TO A THIRD COUNTRY</b>
              </div>
              <div>
                <p>
                  Hafnia has parent and sister companies (within the BW group)
                  and data processors in the EU, the USA and Singapore.
                  Accordingly, your personal data may be transferred to a third
                  country as part of our regular procedures.
                </p>
                <p>
                  SendGrid is used for sending emails from Microsoft Azure and
                  processes and stores data in USA.
                </p>
                <p>
                  Hafnia has ensured that appropriate and suitable safeguards
                  have been implemented to protect your personal data. Hafnia
                  has agreed on the EU Commission’s standard clauses with the
                  recipients of your personal data. You can ask Hafnia for a
                  copy of the agreed provisions.
                </p>
              </div>
            </li>
            <li>
              <div>
                <b>
                  RIGHT TO ACCESS, RECTIFICATION, ERASURE, RESTRICTION, DATA
                  PORTABILITY AND OBJECTION
                </b>
              </div>
              <div>
                <p>
                  You are entitled to access the personal data Hafnia has
                  registered about you. If you wish to be granted such access,
                  Hafnia will send you a copy of the registered data. Electronic
                  requests will be answered electronically, and if you request
                  several copies of your personal data, you should expect Hafnia
                  to charge you a reasonable fee for the related expenses paid
                  by Hafnia.
                </p>
                <p>
                  You are entitled to object to any processing of your personal
                  data.
                </p>
                <p>
                  You are furthermore entitled to demand the rectification or
                  erasure of any of your personal data as well as the
                  restriction of the processing of your personal data.
                </p>
                <p>
                  In some cases, you are entitled to have your personal data
                  returned or transferred to another data processor.
                </p>
                <p>
                  <b>
                    Under the provisions of the General Data Protection
                    Regulation, certain specific situations may exclude Hafnia
                    from the obligation to comply with the above-mentioned
                    rights.
                  </b>
                </p>
                <p>
                  If you wish to exercise your above-mentioned rights, you
                  should contact Hafnia either by email to info@hafniabw.com or
                  by phone +45 3369 9000.
                </p>
              </div>
            </li>
            <li>
              <div>
                <b>DURATION OF STORAGE OF YOUR PERSONAL DATA</b>
              </div>
              <div>
                Your personal data will be erased seven years after the
                termination of the contract relationship/cooperation with
                Hafnia. Hafnia will store your data accordingly in compliance
                with the Danish Bookkeeping Act and will provide documentation
                if claims are raised against Hafnia in connection with the
                relationship.
              </div>
            </li>
            <li>
              <div>
                <b>COMPLAINTS</b>
              </div>
              <div>
                You may complain against the processing of your personal data.
                Your complaint should be submitted to the Danish Data Protection
                Agency, Borgergade 28, 5th floor, 1300 Copenhagen K, Denmark,
                dt@datatilsynet.dk
              </div>
            </li>
          </ol>
        </ModalBody>
      </Modal>
    </div>
  );
};
