export const isNullOrEmpty = (value) =>
  value === "" ||
  value === undefined ||
  value === " " ||
  value?.length === 0 ||
  value === null;

export const setMaxLength = (length, value) => {
  if (value?.length > length) {
    value = value.slice(0, length);
  }
  return value;
};

export const inputPattern = ({ pattern, length = 4, value }) => {
  // Remove non-numeric characters
  const numericValue = value.replace(pattern, "");
  // Limit the input to 4 characters
  const limitedValue = numericValue.slice(0, length);
  return limitedValue;
};

export const Kortart = [
  { key: 1, value: "+01", name: "+01" },
  { key: 2, value: "+04", name: "+04" },
  { key: 3, value: "+15", name: "+15" },
  { key: 4, value: "+41", name: "+41" },
  { key: 5, value: "+71", name: "+71" },
  { key: 6, value: "+73", name: "+73" },
  { key: 7, value: "+75", name: "+75" },
];

export const paymentTypes = {
  FIK: "FIK",
  IBAN: "IBAN",
  SWIFT: "BIC",
};
