import React from "react";

import {
  Modal,
  ModalBody,
  ModalHeader,
  Table,
  Button,
  ModalFooter,
} from "reactstrap";
import { paymentTypes } from "../../../utils/constants";

export const BankDataViewModal = (props) => {
  const toggle = () => props.setToggle(!props.toggle);
  const { closeBtn = false } = props;
  const type = props?.selectedBankData.type;
  return (
    <div>
      <Modal isOpen={props.toggle} toggle={toggle}>
        <ModalHeader toggle={toggle}>View Bank Data</ModalHeader>
        <ModalBody>
          <Table striped>
            <tbody>
              <tr>
                <th scope="row">Beneficiary name</th>
                <td>{props?.selectedBankData.beneficiaryBankAccountName}</td>
              </tr>
              {type === paymentTypes.IBAN ? (
                <tr>
                  <th scope="row">IBAN</th>
                  <td>{props?.selectedBankData.iban}</td>
                </tr>
              ) : type === paymentTypes.SWIFT ? (
                <tr>
                  <th scope="row">BIC/SWIFT</th>
                  <td>{props?.selectedBankData.bic}</td>
                </tr>
              ) : (
                ""
              )}
              {type === paymentTypes.SWIFT && (
                <>
                  <tr>
                    <th scope="row">Registration Number</th>
                    <td>{props?.selectedBankData.regNo}</td>
                  </tr>
                  <tr>
                    <th scope="row">Bank Account Number</th>
                    <td>{props?.selectedBankData.bankAccountNumber}</td>
                  </tr>
                </>
              )}
              {type !== paymentTypes.FIK ? (
                <>
                  {" "}
                  <tr>
                    <th scope="row">Bank</th>
                    <td>{props?.selectedBankData.bank}</td>
                  </tr>
                  <tr>
                    <th scope="row">Branch</th>
                    <td>{props.selectedBankData.branch ?? "N/A"}</td>
                  </tr>
                  <tr>
                    <th scope="row">Address</th>
                    <td>{props.selectedBankData.address ?? "N/A"}</td>
                  </tr>
                  <tr>
                    <th scope="row">City</th>
                    <td>{props.selectedBankData.city ?? "N/A"}</td>
                  </tr>
                  <tr>
                    <th scope="row">Zip</th>
                    <td>{props.selectedBankData.zip ?? "N/A"}</td>
                  </tr>
                  <tr>
                    <th scope="row">Country</th>
                    <td>{props.selectedBankData.country ?? "N/A"}</td>
                  </tr>
                </>
              ) : (
                <>
                  {" "}
                  <tr>
                    <th scope="row">Kortart</th>
                    <td>{props?.selectedBankData.fikKortart}</td>
                  </tr>
                  <tr>
                    <th scope="row">Kreditor/GIRO number</th>
                    <td>{props.selectedBankData.fikNumber ?? "N/A"}</td>
                  </tr>
                </>
              )}
            </tbody>
          </Table>
        </ModalBody>
        {closeBtn && (
          <ModalFooter>
            <Button color="danger" onClick={toggle}>
              Close
            </Button>
          </ModalFooter>
        )}
      </Modal>
    </div>
  );
};
