import React from "react";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { paymentTypes } from "../../../../utils/constants";

const SwiftForm = (props) => {
  return (
    <>
      <FormGroup
        style={{
          display: props.checkbox === paymentTypes.SWIFT ? "block" : "none",
        }}
      >
        <Row>
          <Col sm="12" className={"float-left"}>
            <Button
              color="info"
              className={"float-left"}
              style={{ width: "100%", marginTop: "10px" }}
              onClick={props.onGetBIC}
            >
              VALIDATE BIC/SWIFT and Account number
            </Button>
          </Col>
        </Row>
        {props.errors.iban && <span>This field is required</span>}
      </FormGroup>
      <FormGroup>
        <Label for="exampleText">Bank</Label>
        <Input type="input" name="bank" disabled innerRef={props.register} />
      </FormGroup>
      <FormGroup>
        <Label for="exampleText">Branch</Label>
        <Input type="input" name="branch" disabled innerRef={props.register} />
      </FormGroup>
      <FormGroup>
        <Label>Bank Address</Label>
        <Input type="input" name="address" disabled innerRef={props.register} />
      </FormGroup>
      <FormGroup>
        <Label>City</Label>
        <Input type="input" name="city" disabled innerRef={props.register} />
      </FormGroup>
      <FormGroup>
        <Label>Zip</Label>
        <Input type="input" name="zip" disabled innerRef={props.register} />
      </FormGroup>
      <FormGroup>
        <Label>Country</Label>
        <Input type="input" name="country" disabled innerRef={props.register} />
      </FormGroup>
    </>
  );
};

export default SwiftForm;
