import React, { useCallback, useEffect, useState } from "react";
import "./LoginPage.css";
import {
  Button,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
  Col,
} from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { LabelWithToolTip } from "../../atoms/LabelWithToolTip";
import {
  apiAuthenticate,
  apiGetAuthTypes,
  apiSendAuthCode,
} from "../../../utils/queries";
import { trackPromise } from "react-promise-tracker";
import useCountDown from "react-countdown-hook";

export const LoginPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [token, setToken] = useState(null);
  const [authType, setAuthType] = useState(null);
  const [selectedAuthType, setSelectedAuthType] = useState(null);
  const [authTypes, setAuthTypes] = useState(null);
  const [code, setCode] = useState("");
  const [codeErrorMessage, setCdeErrorMessage] = useState("Required");
  const [codeError, setCodeError] = useState(false);
  const [timeLeft, { start: _start, pause, resume, reset }] = useCountDown();

  const start = () => {
    const newTime = 60 * 5 * 1000;
    _start(newTime);
  };

  const restart = useCallback(() => {
    apiSendAuthCode({ channel: authType.channel.toLowerCase() }, token).then();
    start();
  }, [token, authType]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setCodeError(false);
    if (!code) {
      setCodeError(true);
      setCdeErrorMessage("Required");
      return;
    }
    try {
      const result = await apiAuthenticate(
        token,
        code,
        authType.channel.toLowerCase()
      );
      localStorage.setItem("authToken", result.data);
      navigate(`/${token}`);
    } catch (e) {
      setCodeError(true);
      setCdeErrorMessage("Invalid code");
    }
  };

  useEffect(() => {
    const state = location.state;
    if (state?.token === null || state?.token === undefined) {
      navigate(`/tokenfail`);
    } else {
      setToken(state.token);
    }
  }, [location]);

  useEffect(() => {
    if (token === null) return;

    async function getAuthTypes() {
      try {
        const response = await trackPromise(apiGetAuthTypes(token));
        if (response.data.length === 0) {
          navigate(`/tokenfail`, {
            state: { message: "No authentication method." },
          });
        }
        if (response.data.length === 1) {
          await trackPromise(
            apiSendAuthCode(
              { channel: response.data[0].channel.toLowerCase() },
              token
            )
          );
          setAuthType(response.data[0]);
          start();
        } else {
          setAuthTypes(response.data);
        }
      } catch (e) {
        navigate(`/tokenfail`);
      }
    }

    getAuthTypes().then();
  }, [token]);

  return (
    <Container>
      {authTypes && authType === null ? (
        <>
          <FormGroup>
            <p>Select authentication method</p>
            <select
              className="form-control"
              name="auth-type"
              onChange={(event) => {
                if (event.target.value === "null") {
                  setSelectedAuthType(null);
                  return;
                }
                const type = JSON.parse(event.target.value);
                setSelectedAuthType(type);
              }}
            >
              <option key="select" value="null">
                Select...
              </option>
              {authTypes.map((type) => (
                <option key={type.channel} value={JSON.stringify(type)}>
                  {type.channel} - {type.value}
                </option>
              ))}
            </select>
          </FormGroup>
          <Button
            className="mt-1"
            color="primary"
            disabled={selectedAuthType === null}
            onClick={async () => {
              if (selectedAuthType === null) return;
              await trackPromise(
                apiSendAuthCode(
                  { channel: selectedAuthType.channel.toLowerCase() },
                  token
                )
              );
              setAuthType(selectedAuthType);
              start();
            }}
          >
            Continue
          </Button>
        </>
      ) : authType === null ? (
        <div>Loading...</div>
      ) : null}
      {authType ? (
        <Form onSubmit={handleLogin}>
          <h4>Enter your verification code</h4>
          {authType.channel === "SMS" ? (
            <p>
              We texted your phone {authType.value}. Please enter the code to
              sign in.
            </p>
          ) : (
            <p>
              We send an email to {authType.value}. Please enter the code to
              sign in.
            </p>
          )}

          <p>
            {authTypes === null
              ? null
              : "To change verification method, please Reload this page (Press F5). "}
            If you encounter any issues please contact{" "}
            <a href="mailto:vendorsupport@hafniabw.com">
              vendorsupport@hafniabw.com
            </a>
            .
          </p>
          <FormGroup>
            <LabelWithToolTip
              id="code"
              labelText="Code"
              tooltipText="Please enter the code to sign in."
            />
            <Input
              type="input"
              name="code"
              onChange={(e) => setCode(e.target.value)}
              value={code}
            />
            {codeError && <p className="text-danger">{codeErrorMessage}</p>}
          </FormGroup>
          <Row>
            <Col>
              <Button className="mt-1" color="primary" onClick={handleLogin}>
                Continue
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <span className="align-middle">Did not receive a code? </span>
              {timeLeft > 0 ? (
                <span className="align-middle">{timeLeft / 1000}</span>
              ) : (
                <Button
                  size="sm"
                  color="link"
                  disabled={timeLeft > 0}
                  onClick={restart}
                >
                  Resend code
                </Button>
              )}
            </Col>
          </Row>
        </Form>
      ) : null}
    </Container>
  );
};
