import React from "react";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { LabelWithToolTip } from "../../../atoms/LabelWithToolTip";
import { paymentTypes } from "../../../../utils/constants";
const IbanForm = (props) => {
  return (
    <>
      <FormGroup>
        {props.checkbox === paymentTypes.IBAN ? (
          <Label for="exampleText">IBAN*</Label>
        ) : props.showRegNo ? (
          <LabelWithToolTip
            id="labelTax"
            labelText="Account Number*"
            tooltipText="Please input Account Number (Numbers must be 10 characters)."
          />
        ) : (
          <Label for="exampleText">Account Number*</Label>
        )}

        <Row>
          <Col sm="12">
            {props.checkbox === paymentTypes.IBAN ? (
              <Input
                type="input"
                innerRef={props.register({
                  required: true,
                })}
                name="accountNr"
                onChange={props.resetMetadata}
              />
            ) : (
              <Input
                type="number"
                innerRef={props.register({
                  required: true,
                })}
                name="accountNr"
                onChange={props.handleAccountNo}
              />
            )}

            <div className={"text-danger"}>
              {!props.isAccountNrValid &&
                (props.checkbox === paymentTypes.IBAN
                  ? "Invalid IBAN"
                  : "Invalid Account Number")}
            </div>
            <div className={"text-danger"}>
              {props.errors.accountNr && props.errors.accountNr.type}
            </div>
          </Col>
        </Row>
        {props.errors.iban && <span>This field is required</span>}
      </FormGroup>{" "}
      <FormGroup
        style={{
          display: props.checkbox === paymentTypes.IBAN ? "block" : "none",
        }}
      >
        <Row>
          <Col
            sm="12"
            className={"float-left"}
            style={{
              display: props.checkbox === paymentTypes.IBAN ? "block" : "none",
            }}
          >
            <Button
              style={{ width: "100%", marginTop: "10px" }}
              color="info"
              onClick={props.onGetIBAN}
            >
              VALIDATE IBAN
            </Button>
          </Col>
        </Row>
        {props.errors.iban && <span>This field is required</span>}
      </FormGroup>
      <FormGroup>
        <Label>BIC/SWIFT</Label>
        <Row>
          <Col>
            <Input
              type="input"
              innerRef={props.register({ required: true })}
              name="bic"
              disabled={props.checkbox === paymentTypes.IBAN ? "disabled" : ""}
              onChange={props.resetMetadata}
            />
            <div className={"text-danger"}>
              {!props.isBICValid && "Invalid BIC"}
            </div>
          </Col>
        </Row>
        {props.errors.iban && <span>This field is required</span>}
      </FormGroup>
      <FormGroup>
        <Label>Corresponding BIC/SWIFT</Label>
        <Row>
          <Col>
            <Input
              type="input"
              innerRef={props.register({ required: false })}
              name="correspondingBic"
              onChange={props.resetMetadata}
            />
            <div className={"text-danger"}>
              {!props.isCorrespondingBICValid && "Invalid BIC"}
            </div>
          </Col>
        </Row>
        {props.errors.iban && <span>This field is required</span>}
      </FormGroup>
    </>
  );
};

export default IbanForm;
