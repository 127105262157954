import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const Confirm = (props) => {
  const toggle = () => props.setToggle(!props.toggle);

  const onConfirm = async () => {
    props.onConfirm();
    toggle();
  };

  return (
    <Modal
      isOpen={props.toggle}
      toggle={toggle}
      className={"modal-dialog-center"}
    >
      <ModalHeader toggle={toggle}>Confirm</ModalHeader>
      <ModalBody>{props.text}</ModalBody>
      <ModalFooter>
        <Button color="danger" type="submit" onClick={onConfirm}>
          Confirm
        </Button>{" "}
        <Button onClick={toggle}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
};

export default Confirm;
