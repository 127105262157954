import React from 'react'
import { Header } from './components/organisms/Header'
import Main from './components/organisms/Main'
import authContext, {
  authenticated,
  authenticationToken,
  logout
} from './utils/useAuth'

const App = () => {
  
  return (
    <authContext.Provider value={{ authenticated, authenticationToken, logout }}>
      <div>
        <Header/>
        <Main/>
      </div>
    </authContext.Provider>
  )
}

export default App
