import React from "react";
import { Kortart, paymentTypes } from "../../../../utils/constants";
import {
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  InputGroup,
  InputGroupText,
} from "reactstrap";
const FikFrom = (props) => {
  return (
    <FormGroup
      style={{
        display: props.checkbox === paymentTypes.FIK ? "block" : "none",
      }}
    >
      <FormGroup>
        <Label for="exampleEmail"> Please select Kortart</Label>
        <select
          ref={props.register({ required: true })}
          className="form-control"
          name="fikkortart"
          onChange={props.handleKortartChange}
        >
          {Kortart?.map((item, index) => (
            <option key={item.key} value={item.value}>
              {item.name}
            </option>
          ))}
        </select>
      </FormGroup>
      <FormGroup
        style={{
          display: props.checkbox === paymentTypes.FIK ? "block" : "none",
        }}
      >
        <Label for="exampleText">Please enter the Kreditor/GIRO number</Label>
        <Row>
          <Col sm="12">
            <InputGroup>
              <InputGroupText>
                <strong className="font-weight-bold">+</strong>
              </InputGroupText>
              <Input
                type="text"
                innerRef={props.register({ required: true })}
                name="fikNumber"
                value={props.fikNumber}
                onChange={props.handleOnChangeFik}
              />
            </InputGroup>
          </Col>
        </Row>
        <div className={"text-danger"}>
          {props.errors.fikNumber && props.errors.fikNumber.type}
        </div>
      </FormGroup>
    </FormGroup>
  );
};

export default FikFrom;
