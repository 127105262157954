import React, { useEffect } from "react";
import "./TokenFailPage.css";

import { Container } from "reactstrap";
import { useLocation } from "react-router-dom";

const TokenFailPage = () => {
  const location = useLocation();
  const message = location.state?.message;

  return (
    <Container>
      <div className="alert alert-danger" role="alert">
        <h4 className="alert-heading">You are not authorized!</h4>
        <p>Please contact Hafnia .....</p>
        {message ? <p>{message}</p> : null}
      </div>
    </Container>
  );
};

export default TokenFailPage;
