import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import { Label, Tooltip } from "reactstrap";

export function LabelWithToolTip(props) {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  return (
    <>
      <Label style={{ marginRight: "10px !important", paddingRight: "10px" }}>
        {props.labelText}
        <FontAwesomeIcon
          style={{ marginLeft: 10 }}
          id={"toolTip" + props.id}
          icon={faInfoCircle}
          color={"#195e83"}
        />
      </Label>

      <Tooltip
        placement="right"
        isOpen={tooltipOpen}
        target={"toolTip" + props.id}
        toggle={() => setTooltipOpen(!tooltipOpen)}
      >
        {props.tooltipText}
      </Tooltip>
    </>
  );
}
