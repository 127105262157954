import React from "react";
import Logo from "../../assets/hafnia-logo.png";

export const Header = () => {
  return (
    <div className="d-flex flex-column flex-md-row align-items-center p-3 px-md-4 mb-3 bg-white border-bottom box-shadow">
      <div className="navbar-left my-0 mr-md-auto">
        <img height="46" src={Logo} alt="" />
      </div>
    </div>
  );
};
