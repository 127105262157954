import React from "react";

import { FilesForm } from "./FilesForm";

import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";

export const FilesModal = (props) => {
  const toggle = () => props.setToggle(!props.toggle);
  const { closeBtn = false } = props;
  return (
    <div>
      <Modal isOpen={props.toggle} toggle={toggle}>
        <ModalHeader toggle={toggle}>Upload files</ModalHeader>
        <ModalBody>
          <FilesForm
            bankDataId={props?.bankData?.id}
            accountId={props?.accountId}
            name="filesModal"
            fileList={props.fileList}
            setFileList={props.setFileList}
            token={props.token}
          ></FilesForm>
        </ModalBody>
        {closeBtn && (
          <ModalFooter>
            <Button color="danger" onClick={toggle}>
              Close
            </Button>
          </ModalFooter>
        )}
      </Modal>
    </div>
  );
};
