import axios from "axios";
import config from "../config";
import { authenticationToken } from './useAuth'

axios.interceptors.request.use(async config => {
  if(config.url.includes("/api/auth/")) return config;
  if (config.headers.Authorization !== undefined && config.headers.Authorization !== null) {
    return config
  }

  const authToken = authenticationToken()
  if (authToken === null) {
    window.location.reload()
  }
  
  config.headers.Authorization = `Bearer ${authToken}`
  return config
})

const apiGet = async (url, token) => {
  return axios.get(`${config.API_URL}${url}`, {
    headers: { "X-API-KEY": token },
  });
};

const apiDelete = async (url, token) => {
  return axios.delete(`${config.API_URL}${url}`, {
    headers: { "X-API-KEY": token },
  });
};

const apiPost = async (url, form, token) => {
  return axios.post(`${config.API_URL}${url}`, form, {
    headers: { "X-API-KEY": token },
  });
};

export const apiGetBankDataFromIBAN = async (iban, token) => {
  return apiGet(`iban/${iban}`, token);
};

export const apiGetBankDataFromBIC = async (accountNr, bic, token) => {
  return apiGet(`bic?bic=${bic}&accountNr=${accountNr}`, token);
};

export const apiGetAccount = async (token) => {
  return apiGet("account", token);
};

export const apiPostBankData = async (bankData, token) => {
  return apiPost("bankdata", bankData, token);
};

export const apiDeleteBankData = async (token, id) => {
  return apiDelete(`bankdata/${token}/${id}`, token);
};

export const apiDeleteFile = async (token, fileName) => {
  return apiDelete(`files/${fileName}`, token);
};

export const apiPostFiles = async (form, token) => {
  return apiPost("files", form, token);
};

export const apiUpdateAccount = async (account, token) => {
  return apiPost("account", account, token);
};

export const apiUpdateGiro = async (isGiro, token) => {
  return apiGet(`account/banktype?isGiro=${isGiro}`, token);
};

export const apiCompleteAccount = async (token) => {
  return apiGet("account/complete", token);
};

export const apiBankDataSetPrimary = async (bankDataId, token) => {
  return apiGet(`bankdata/setprimary/${bankDataId}`, token);
};

export const apiGetAuthTypes = async (token) => {
  return apiGet("auth/auth-types", token);
};

export const apiSendAuthCode = async (data, token) => {
  return apiPost("auth/SendCode", data, token);
};

export const apiAuthenticate = async (key, code, channel) => {
  return apiPost(
    "auth/authenticate",
    {
      key: key,
      code: code,
      channel: channel
    },
    key
  );
};
