import React, { useContext } from "react";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import CompletedPage from "../pages/CompletedPage/CompletedPage";
import TokenFailPage from "../pages/TokenFailPage/TokenFailPage";
import { LoginPage } from "../pages/LoginPage/LoginPage";
import HomePage from "../pages/HomePage/HomePage";
import authContext from "../../utils/useAuth";
import jwtDecode from 'jwt-decode'

// The Main component renders one of the three provided
// Routes (provided that one matches). Both the /roster
// and /schedule routes will match any pathname that starts
// with /roster or /schedule. The / route will only match
// when the pathname is exactly the string "/"

function RequireAuth({ children }) {
  let { token } = useParams();
  const { authenticated, logout } = useContext(authContext);
 
  if (authenticated(token) === true) return children;

  logout();

  return <Navigate to="/login" state={{ token: token }} replace />;
}

const Main = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/tokenfail" replace />} />
      <Route path="/tokenfail" element={<TokenFailPage />} />
      <Route path="/complete" element={<CompletedPage />} />
      <Route
        path=":token"
        element={
          <RequireAuth>
            <HomePage />
          </RequireAuth>
        }
      />
      <Route path="/login" element={<LoginPage />} />
    </Routes>
  );
};

export default Main
